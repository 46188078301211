.not-found-container {
  /* Content */
  width: 1392px;
  padding-bottom: 62px;

  /* Position */
  position: relative;
  top: calc(160px * var(--zoom-level));

  /* Text */
  text-align: center;
}

.not-found-container > h1 {
  /* Position */
  margin: 0;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 140px;
  line-height: 144px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.not-found-container > h2 {
  /* Position */
  margin-bottom: 48px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 44px;
  line-height: 48px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.not-found-container > p {
  /* Content */
  width: 800px;

  /* Position */
  margin: 0 auto;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
}

.not-found-container a {
  /* Text */
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.not-found-container a:hover {
  /* Text */
  color: #085FDB;
}
