:root {
  /* transition */
  --transition-timing: 0.2s;
  --transition-function: linear;

  /* scale */
  --zoom-level: 1;

  /* Miscelenious */
  --code-editor-gutter: var(--code-editor-outline);
  --task-content-position: relative;
  --task-content-top: 0px;
}

body, html {
  /* Content */
  height: 100%;
  
  /* Position */
  margin: 0px;
}

body {
  /* Content */
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0 !important;
}

#root {
  /* Content */
  height: 100%;
}

@font-face {
  font-family: Gilroy;
  src: url(../other/Gilroy_ExtraBold.woff2);
}

@font-face {
  font-family: PT-Root-UI-Light;
  src: url(../other/PT-Root-UI_Light.woff2);
}

@font-face {
  font-family: PT-Root-UI;
  src: url(../other/PT-Root-UI_Regular.woff2);
}

@font-face {
  font-family: PT-Root-UI-Medium;
  src: url(../other/PT-Root-UI_Medium.woff2);
}

@font-face {
  font-family: JetBrains-Mono;
  src: url(../other/JetBrainsMono-Regular.woff2);
}

.header {
  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif !important;
  font-weight: 800 !important;
  font-style: normal !important;
  color: var(--text) !important;
  transition: color var(--transition-timing) var(--transition-function) !important;
}

.header-light { 
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

h1 {
  /* Text */
  font-size: 48px;
  line-height: 59px;
}

p {
  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 400;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

h1, h2, h3, h4, h5, h6, p {
  /* Position */
  margin-top: 0;
}

label {
  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-style: normal;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

input {
  text-decoration: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

a {
  /* Text */
  text-decoration: none;
}

textarea {
  resize: none;
}
