.task-container {
  /* Content */
  width: 1154px;
  display: flex;

  /* Position */
  position: var(--task-content-position);
  margin-left: calc(238px * var(--zoom-level));
  top: calc((62px - var(--task-content-top)) * var(--zoom-level));
}

.task-sidebar {
  /* Content */
  width: 196px;
  padding: 0px 22px 0px 20px;

  /* Position */
  position: fixed;
  top: calc(62px * var(--zoom-level));
  bottom: 0%;
}

.task-list-scroll-wrapper {
  /* Visual */
  overflow-y: hidden;

  /* Content */
  height: calc(100% / var(--zoom-level));
}

.task-list-scroll-wrapper > a {
  /* Position */
  position: relative;
  top: 20px;
}

.task-list-scroll-wrapper ul:last-child{
  /* Content */
  padding-bottom: 62px;
}

.task-sidebar a {
  /* Visual */
  cursor: pointer;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-sidebar a:hover {
  /* Text */
  color: var(--task-link-hover);
}

.task-sidebar a svg {
  /* Visual */
  fill: var(--difficulty);
  transition: fill var(--transition-timing) var(--transition-function);
}

.task-sidebar a:hover svg {
  /* Text */
  fill: var(--task-link-hover);
}

.task-content {
  /* Content */
  width: 600px;
  padding: 35px 36px 62px 36px;
}

#task-vertical-line {
  /* Visual */
  border-right: 2.5px solid var(--nav-hover-color);
  transition: border var(--transition-timing) var(--transition-function);

  /* Content */
  height: calc(100% / var(--zoom-level));
  width: 238px;
  
  /* Position */
  position: fixed;
}

.task-content > h2 {
  /* Position */
  margin-top: 0px;
  margin-bottom: 18px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-sidebar ul {
  /* Content */
  padding: 0;

  /* Position */
  margin: 10px 0 0 0;
}

.task-link-current {
  /* Position */
  margin-top: 53px;

  /* Text */
  color: #3680C4;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
}

.task-content .difficulty {
  /* Content */
  display: inline;
  padding-left: 12px;

  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-submitted {
  /* Content */
  width: 400px;
  padding: 52px 36px 62px 36px;
}

.task-text-block {
  /* Position */
  margin-top: 32px;

  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-text-block h1, .task-text-block h2, .task-text-block h3, .task-text-block h4, .task-text-block h5, .task-text-block h6 {
  font-family: Gilroy, Tahoma, Sans-Serif;
}

.task-text-block p, .task-edit__block p, .task-edit__legend p {
  /* Content */
  word-wrap: break-word;
}

.task-text-block code, .task-edit__block code, .task-edit__legend code {
  /* Visual */
  background: var(--sample-background);
  transition: background var(--transition-timing) var(--transition-function);
  border-radius: 4px;

  padding: 1px;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
}

.task-text-block li:not(:last-child), .task-edit__block li:not(:last-child), .task-edit__block li:not(legend-child) {
  /* Position */
  margin-bottom: 5px;
}

.task-text-block.task-text-addition > p:last-child {
  /* Position */
  margin: 0;
}

.task-category-header {
  /* Position */
  margin-bottom: 12px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
}

.subtask-header td {
  /* Text */
  color: var(--task-table-header);
  transition: color var(--transition-timing) var(--transition-function);
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.task-submitted .subtask-item, .task-submitted .subtask-item * {
  /* Visual */
  cursor: pointer;
}

.task-alert .subtask-item * {
  /* Visual */
  cursor: pointer !important;
}

.admin-back-button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 6px 8px;

  /* Psoition */
  position: relative;
  top: -6px;
  left: -8px;
  margin-bottom: 26px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.subtask-item {
  /* Position */
  margin-top: 6px;
}

.subtask-item td {
  /* Visual */
  background: var(--subtask-background);
  transition-property: background, color, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
  
  /* Content */
  padding: 8px 8px 8px 0;
  
  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--text);
}

.subtask-item td:first-child {
  /* Visual */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  /* Content */
  padding-left: 12px;
  padding-right: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  color: var(--subtask-index);
}

.subtask-item td:last-child {
  /* Visual */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.subtask-item-container {
  /* Content */
  display: flex;
  align-items: center;
}

.subtask-item-container p {
  /* Position */
  margin-left: 10px;
  margin-bottom: 0;
}

.subtask-item-container p:last-child {
  /* Position */
  margin-left: 10px;
}

.task-content img {
  /* Content */
  max-width: 600px;
  display: block;

  /* Position */
  margin-left: auto;
  margin-right: auto;
}

.samples-item td {
  /* Visual */
  background: var(--sample-background);
  transition-property: background, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  vertical-align: top;
  padding: 0;
}

@keyframes blink {
  50% {
    background: var(--input-color);
  }

  100% {
    background: unset;
  }
}

.samples-item td > div.samples-blinking {
  animation: 0.25s ease-in-out blink;
}

.samples-item td > div {
  /* Visual */
  transition: background 0.25s var(--transition-function);

  /* Content */
  display: flex;
  padding: 8px 8px 8px 10px;
  box-sizing: border-box;
}

.samples-copy-button {
  /* Visual */
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  /* Content */
  width: 18px;
  height: 18px;

  /* Position */
  position: relative;
}

.samples-copy-button > svg {
  /* Visual */
  fill: var(--rating-page);
  transition: fill var(--transition-timing) var(--transition-function);

  /* Position */
  position: absolute;
  left: 0px;
  top: 0px;
}

.samples-copy-button:hover > svg {
  /* Visual */
  fill: var(--input-outline);
}

.samples-item td:last-child {
  /* Visual */
  border-left: 10px solid var(--background);
}

.samples-item pre {
  /* Visual */
  overflow: hidden;

  /* Content */
  width: 259px;

  /* Position */
  margin: 0;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

#task-login {
  /* Position */
  margin-top: 32px;
}

.code-editor {
  /* Visual */
  border-top: 1px solid var(--code-editor-outline);
  border-left: 1px solid var(--code-editor-outline);
  border-right: 1px solid var(--code-editor-outline);
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  background: var(--sample-background);
  transition-property: background, color, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  max-height: 500px;
  min-height: 204px;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--text);
}

.code-editor.submission {
  /* Visual */
  border: 1px solid var(--code-editor-outline);
  border-radius: 4px;
}

.code-editor.preload *, .code-editor.preload {
  /* Visual */
  transition: none !important;
}

.code-editor .ace_gutter {
  /* Visual */
  background: none;

  /* Text */
  color: var(--code-numeration);
  transition: color var(--transition-timing) var(--transition-function);
}

.code-editor .ace_placeholder {
  /* Content */
  transform: none;

  /* Position */
  top: 12px;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #888888;
}

.code-editor .ace_gutter-cell.ace_gutter-active-line {
  /* Visual */
  background: var(--code-editor-gutter);
  transition: background var(--transition-timing) var(--transition-function);
}

.code-editor .ace_scrollbar {
  /* Visual */
  display: none;
}

.code-editor .ace_cursor {
  /* Visual */
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.ace_mobile-menu {
  /* Visual */
  display: none !important;
}

#mode-selector {
  /* Content */
  width: 169px;

  /* Position */
  position: absolute;
  bottom: -18px;
  right: 14px;
  z-index: 101;
}

#file-uploader {
  /* Content */
  width: 100%;

  /* Position */
  position: absolute;
  top: 87px;
  bottom: 0;
  z-index: 100;

  /* Text */
  text-align: center;
  color: #888888;
}

#file-uploader p {
  /* Content */
  width: 271px;

  /* Position */
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #888888;
}

#code-horizontal-line {
  /* Position */
  position: absolute;
  left: 26px;
}

.editor-chin {
  /* Visual */
  border-bottom: 1px solid var(--code-editor-outline);
  border-left: 1px solid var(--code-editor-outline);
  border-right: 1px solid var(--code-editor-outline);
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  background: var(--sample-background);
  transition-property: background, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 600px;
  height: 24px;
}

#send-task {
  /* Content */
  width: 214px;
  height: 40px;
  padding: 10px 8px;
}

.task-send-block {
  /* Content */
  width: 600px;

  /* Position */
  margin-top: 49px;
}

.task-send-block p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.01em;
  color: var(--difficulty);
}

.ace_hidden-cursors {
  opacity: 0;
}

.submisson-black p, .submisson-green p, .submisson-red p {
  /* Visual */
  cursor: default;

  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  text-transform: uppercase;
  font-size: 14px;
}

.submisson-black td:first-child p, .submisson-green td:first-child p, .submisson-red td:first-child p {
  /* Position */
  margin-left: 1px;

  /* Text */
  font-size: 16px;
}

.submisson-black td:last-child p, .submisson-green td:last-child p, .submisson-red td:last-child p {
  /* Text */
  font-size: 16px;
}

.submisson-green p {
  /* Text */
  color: var(--submission-green);
}

.submisson-red p {
  /* Text */
  color: var(--submission-red);
}

.task-alert {
  /* Visual */
  background: var(--background);
  border-radius: 10px;
  border: 1px solid var(--code-editor-outline);
  transition-property: background, order;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 1000px;
  padding: 48px 32px;
  box-sizing: border-box;

  /* Position */
  position: absolute;
  top: calc(100px * var(--zoom-level));
  left: calc(220px * var(--zoom-level));
}

.task-alert::after {
  content: "";
  height: 110px;
  width: 1000px;
  position: absolute;
}

.task-alert-background {
  /* Visual */
  background: var(--alert-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  width: 100%;
  height: 100%;

  /* Position */
  position: fixed;
  top: 0;
  left: 0;
}

.task-alert > h2 {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-alert h3 {
  /* Content */
  max-width: 605px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;

  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-alert > .title {
  /* Content */
  display: flex;
  align-items: center;
}

.task-alert > .title img {
  /* Position */
  margin-right: 12px;
}

.admin-submission-list {
  /* Position */
  margin-top: 32px;
}

.admin-submission-list > thead th {
  /* Content */
  padding: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--task-table-header);
  transition: color var(--transition-timing) var(--transition-function);
}

.admin-submission-list {
  /* Content */
  border-collapse: separate;
  border-spacing: 0px 8px;
}

.subtask-item .subtask-item-container.secondary > span {
  /* Position */
  margin-left: 10px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-alert.task-alert-admin {
  /* Content */
  width: 741px;
  box-sizing: border-box;

  /* Position */
  left: calc(348px * var(--zoom-level));
}

.task-alert.task-alert-admin:after {
  /* Content */
  width: 741px;
  left: 0;
}

.task-alert > .task-alert-close {
  /* Content */
  padding: 12px;
  min-width: unset;

  /* Position */
  position: absolute;
  right: 20px;
  top: 20px;
}

.task-alert > .task-alert-close:hover {
  /* Visual */
  background: unset;
}

.task-alert > .task-alert-close:hover path {
  /* Visual */
  fill: var(--input-outline);
}

.task-alert span:not(.code-editor span, .alert-plain > span, button span) {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-alert .code-editor *, .task-alert .code-editor {
  /* Visual */
  transition: none !important;
}

.task-alert > .code-editor .ace_gutter-cell.ace_gutter-active-line {
  /* Visual */
  background: var(--sample-background);
}

.task-alert-block {
  /* Position */
  margin-top: 32px;
}

.alert-category-header {
  /* Position */
  margin-bottom: 16px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
}

.task-alert-block p.alert-plain {
  /* Position */
  margin-bottom: 0;

  /* Text */
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.task-alert-block svg:not(.task-alert-separator, .subtask-item-container svg) {
  /* Positioon */
  margin-right: 12px;
}

.compiler-log {
  /* Visual */
  border: 1px solid var(--code-editor-outline);
  border-radius: 4px;

  /* Content */
  min-height: 0px;

  /* Position */
  margin-top: 32px;
}

.compiler-log * {
  /* Text */
  color: var(--compilation-error) !important;
}

.compiler-log .ace-chrome .ace_indent-guide {
  /* Visual */
  background: none;
}

.task-alert-separator {
  /* Content */
  width: 100%;

  /* Position */
  margin-top: 32px;
}

.alert-plain > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
}

.worst-time {
  /* Position */
  margin-top: 16px;
  margin-bottom: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-submitted > .task-show-more, .task-alert .task-show-more {
  /* Content */
  height: 22px;

  /* Text */
  text-transform: none;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-submitted > .task-show-more {
  /* Content */
  width: 400px;
}

.task-alert .task-show-more {
  /* Content */
  width: 120px;
}

.task-submitted > .task-show-more:hover, .task-alert .task-show-more:hover {
  /* Visual */
  background: none;
  color: #085FDB;
}

.loader-container > .more-tip {
  /* Position */
  margin-top: 8px;

  /* Text */
  text-transform: none;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.loader-container > .more-tip:hover, .loader-container > .more-tip:hover p, .loader-container > .more-tip:hover path {
  /* Visual */
  background: none;
  color: #085FDB;
  fill: #085FDB;
}

.loader-container > .more-tip p {
  /* Content */
  padding-left: 15px;

  /* Position */
  margin: 0;

  /* Text */
  text-transform: none;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.loader-container .tip {
  /* Position */
  display: inline-block;
  align-self: flex-end;
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #696969; /* TODO: change to var */
}

.top-text > #task-switcher {
  /* Content */
  width: 155px;
  height: 44px;

  /* Position */
  position: absolute;
  right: 0;
  top: 0;

  /* Text */
  font-size: 14px;
  line-height: 18px;
}

.task-list-container {
  /* Content */
  display: flex;
}

.task-list-filter {
  /* Content */
  width: 268px;

  /* Position */
  position: relative;
}

#task-list-search {
  /* Content */
  width: 100%;
}

.task-list-visible {
  /* Content */
  padding-left: 37px;
}

.tasklist-header th {
  /* Content */
  padding: 0 0 11.5px 0;

  /* Text */
  color: var(--placeholder-text);
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-align: left;
}

.tasklist-item {
  background: var(--sample-background);
  transition: background var(--transition-timing) var(--transition-function);
}

.tasklist-item td {
  /* Visual */
  transition: color var(--transition-timing) var(--transition-function);
  
  /* Content */
  padding: 15px 15px 15px 0;

  /* Position */
  position: relative;
  
  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--text);
}

.tasklist-item td:first-child {
  /* Visual */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  /* Content */
  padding-left: 14px;
  padding-right: 8px;
}

.tasklist-item td:last-child {
  /* Visual */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tasklist-item td a {
  /* Content*/
  width: 100%;
  height: 100%;

  /* Position */
  position: absolute;
  top: 0;
  left: 0;
}

.task-check {
  /* Position */
  margin-left: 16px;
}

.task-check > * {
  /* Visual */
  transition: all var(--transition-timing) var(--transition-function);
}

.tasklist-item-container {
  /* Content */
  display: flex;
  align-items: center;
}

.tasklist-item-container p {
  /* Content */
  overflow: hidden;
  max-width: 800px;
  text-overflow: ellipsis;

  /* Position */
  margin-left: 13px;
  margin-bottom: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
}

.solved .tasklist-item-container p:first-of-type {
  color: var(--submission-green);
}

.tasklist-item-container p:first-child {
  /* Position */
  margin-left: 0;

  /* Text */
  color: var(--tasklist-id);
}

.tasklist-item td:last-child .tasklist-item-container > svg:last-of-type {
  /* Position */
  margin-left: 16px;
}

.hide-solved {
  /* Position */
  margin-top: 23px;
}

.tasklist-separator {
  /* Content */
  width: 100%;

  /* Position */
  padding-top: 32px;
}

.sort-form {
  /* Content */
  padding-top: 32px !important;
}

.task-list-filter .MuiButtonBase-root:not(.MuiButtonBase-root.MuiSwitch-switchBase) {
  margin-left: 2px;
}

.checkbox-difficulty {
  /* Content */
  padding-left: 8px;
}

.task-list-filter .categories-select-all {
  /* Position */
  position: absolute;
  top: 26px;
  left: 64px;

  /* Text */
  text-transform: none;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-list-filter .categories-select-all:hover {
  /* Visual */
  background: none;
  color: #085FDB;
}

.task-filter-dim {
  /* Visual */
  background: rgba(calc((1 - var(--fade-invert)) * 256), calc((1 - var(--fade-invert)) * 256), calc((1 - var(--fade-invert)) * 256), 0.5);
  transition: all var(--transition-timing) var(--transition-function);

  /* Content */
  width: 100%;
  height: 100%;

  /* Position */
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 2;
}

.task-sidebar span.task-link-current, .task-sidebar span.task-link-current:hover {
  /* Visual */
  color: #3680C4;
  cursor: default;

  /* Tetx */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 17px;
  line-height: 21px;
}

.task-sidebar span.task-link-current > svg, .task-sidebar span.task-link-current:hover > svg {
  /* Visual */
  fill: #3680C4;
}

.task-archive-dropdown-title {
  /* Visual */
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */

  /* Content */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 4px 0;
}

.task-archive-dropdown {
  transition: all 0.2s;
}

.task-archive-dropdown-title > p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 17px;
  line-height: 21px;
}

.task-archive-dropped > ul:last-child {
  /* Content */
  padding-bottom: 0;
}

.task-list-scroll-wrapper > div:last-child {
  /* Content */
  padding-bottom: 62px;
}

.task-archive-dropdown svg {
  /* Visual */
  transition: all 0.2s;
}

.task-archive-dropdown.open svg {
  /* Visual */
  transform: rotateX(180deg);
}

.task-archive-dropdown-title:hover svg {
  /* Visual */
  transform: rotateX(180deg);
}

.task-archive-dropdown.open .task-archive-dropdown-title:hover svg {
  /* Visual */
  transform: rotateX(0);
}

@keyframes slide-down {
  from { 
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from { 
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-15px);
  }
}

.task-archive-dropdown .task-archive-dropped {
  /* Visual */
  animation: slide-up 0.2s ease-in-out;
  
}

.task-archive-dropdown.open .task-archive-dropped  {
  /* Visual */
  animation: slide-down 0.2s ease-in-out;
}

.contest-task-title {
  /* Position */
  margin-top: 20px;
  margin-bottom: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
}

.contest-task-timer {
  /* Content */
  display: flex;

  /* Position */
  margin-top: 16px;
}

.contest-task-timer > * {
  /* Visual */
  fill: var(--difficulty);
  color: var(--difficulty);
  transition-property: color, fill;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
}

@keyframes rotation-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.contest-task-timer.contest-ended > svg {
  /* Visual */
  fill: var(--submission-red);
  transition: all var(--transition-timing) var(--transition-function);
  animation: rotation-in 0.4s ease-in-out;
  animation-fill-mode: forwards
}

.contest-task-timer.contest-ended > span {
  /* Visual */
  color: var(--submission-red);
}

.contest-task-timer > span {
  /* Position */
  margin-left: 8px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-submit-blocker {
  /* Visual */
  background: rgba(calc((1 - var(--fade-invert)) * 256), calc((1 - var(--fade-invert)) * 256), calc((1 - var(--fade-invert)) * 256), 0.85);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  width: 600px;
  height: 344px;

  /* Position */
  position: absolute;
  z-index: 1000;
  margin-top: 16px;

  /* Text */
  text-align: center;
}

.contest-submit-blocker > h2 {
  /* Content */
  width: 340px;

  /* Position */
  margin: 88px auto 24px auto;

  /* Text */
  font-size: 24px;
  line-height: 29px;
}

.contest-submit-blocker > p {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
}

.contest-task-wait-loader {
  /* Content */
  width: 936px;
  margin: 194px auto 0 auto;
}

.contest-sidebar-unread {
  /* Visual */
  background: #DF4545;
  border-radius: 50%;

  /* Content */
  width: 18px;
  height: 18px;
}

.contest-sidebar-unread > p {
  /* Position */
  position: relative;
  top: 1.5px;
  margin: 0;

  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.contest-jury-connection {
  /* Content */
  width: 1156px;
  padding: 35px 36px 62px 36px;
}

.contest-jury-connection > h2 {
  /* Position */
  margin-top: 0px;
  margin-bottom: 18px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.announcement-container {
  /* Content */
  display: flex;
  align-items: flex-start;
  width: 600px;
}

.announcement-container:not(.announcement-container:last-child) {
  /* Content */
  margin-bottom: 20px;
}

.announcement-container p {
  /* Position */
  margin: 0;

  /* Text */
  font-size: 15px;
  line-height: 19px;
}

.announcement-container > div {
  /* Content */
  padding-left: 14px;
}

.announcement-timestamp {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--placeholder-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-jury-chat {
  /* Content */
  width: 1082px;
  padding-bottom: 0;
  pointer-events: none;

  /* Position */
  height: calc(100% / var(--zoom-level));
  position: fixed;
  z-index: 1000;
}

.contest-jury-chat > * {
  /* Content */
  pointer-events: auto;
}

.jury-chat-input-container {
  /* Position */
  position: absolute;
  bottom: 140px;
}

.jury-chat-input-container::before {
  /* Visual */
  background: linear-gradient(0deg, rgba(255, 255, 255, 1)  0%, rgba(255, 255, 255, 1) 80%, rgba(0, 0, 0, 0) 100%);
  filter: invert(var(--fade-invert));
  transition-property: filter, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  content: '';
  width: 600px;
  height: 270px;

  /* Position */
  position: absolute;
  z-index: -1;
  bottom: -60px;
}

.contest-jury-chat-container {
  /* Position */
  position: relative;
  top: calc(62px * var(--zoom-level));
  margin-left: calc(238px * var(--zoom-level));
}

.jury-chat-messages {
  /* Content */
  padding-bottom: 100px;
  padding-top: 120px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap-reverse;
  min-height: calc(100vh / var(--zoom-level) - 282px);

  /* Position */
  position: absolute;
}

.jury-chat-messages > *:first-child {
  /* Content */
  padding-bottom: 138px;
}

.contest-jury-chat > h2::before {
  /* Visual */
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 70%, rgba(0, 0, 0, 0) 100%);
  filter: invert(var(--fade-invert));
  transition: filter var(--transition-timing) var(--transition-function);

  /* Content */
  content: '';
  width: 600px;
  height: 138px;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: -1;
  top: -4px;
}

.chat-message {
  /* Content */
  width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  /* Position */
  margin-left: 37px;
  z-index: 1;
}

.chat-message-content {
  /* Content */
  width: 550px;
  padding-bottom: 20px;
}

.chat-message-content > p {
  /* Position */
  margin: 0;

  /* Text */
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 19px;
}

body.body-transitioning .chat-message-header > p > span {
  /* Visual */
  transition: color var(--transition-timing) var(--transition-function);
}

.chat-message-header > p > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--text);
}

.chat-message-header > p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--placeholder-text);
}

.jury-chat-tip-container {
  /* Position */
  float: right;
  margin-top: 8px;
}

.jury-chat-tip {
  /* Visual */
  background: var(--subtask-background);
  transition: background var(--transition-timing) var(--transition-function);
  border-radius: 5px;

  /* Content */
  width: 332px;
  padding: 16px 23px 16px 16px;
}

.jury-chat-tip:last-child {
  /* Position */
  margin-top: 12px;
}

.jury-chat-tip > h3 {
  /* Position */
  margin-bottom: 12px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.jury-chat-tip > ul {
  /* Content */
  padding-left: 24px;

  /* Position */
  margin: 12px 0 24px 0;
}

.jury-chat-tip > ul > li::marker {
  /* Visual */
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.jury-chat-tip > ul p {
  /* Position */
  margin: 0;

  /* Text */
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.jury-chat-no-messages {
  /* Content */
  width: 600px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: absolute;
  margin-left: 37px;
}

.jury-chat-no-messages > p {
  /* Position */
  margin: 0;

  /* Text */
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.jury-chat-no-messages path {
  /* Visual */
  transition: fill var(--transition-timing) var(--transition-function);
}

.contest-rating {
  /* Content */
  width: 1156px;
  padding: 35px 36px 62px 36px;
}

.contest-rating > h2 {
  /* Position */
  margin-top: 0px;
  margin-bottom: 16px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-refresh-button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 6px 8px;
  display: flex;
  width: 130px;
  justify-content: space-between;
  align-items: center;

  /* Position */
  position: relative;
  left: -8px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-refresh-button > svg {
  /* Visual */
  fill: var(--button-color);
}

.rating-refresh-button.disabled {
  /* Visual */
  color: var(--button-disabled);
  cursor: default;
}

.rating-refresh-button.disabled > svg {
  /* Visual */
  fill: var(--button-disabled);
}

.rating-refresh-button:not(.disabled):hover, .rating-refresh-button:not(.disabled):hover > svg {
  /* Visual */
  color: #085FDB;
  fill: #085FDB;
}

.rating-table {
  /* Visual */
  background: var(--subtask-background);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid var(--input-color);

  /* Content */
  padding: 0 16px 0 12px;
  border-spacing: 0;
  width: 1084px;

  /* Position */
  margin-top: 12px;
}

.rating-table th {
  /* Visual */
  border-bottom: 1px solid var(--input-color);
  transition: border var(--transition-timing) var(--transition-function);

  /* Content */
  height: 36px;
}

.rating-table th:first-child {
  /* Content */
  width: 368px;
}

.rating-table th:last-child {
  /* Content */
  display: flex;
  width: 668px;
  padding: 4px 0 0 0;
}

.rating-table th span {
  /* Content */
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-table.small th span, .rating-table.small th svg, .rating-table.small .rating-cell > div {
  /* Content */
  width: 60px;
}

.rating-table.middle th span, .rating-table.middle th svg, .rating-table.middle .rating-cell > div {
  /* Content */
  width: 48px;
}

.rating-table.big th span, .rating-table.big th svg, .rating-table.big .rating-cell > div {
  /* Content */
  width: 36px;
}

.rating-table th div:not(div:first-child), .rating-table .rating-cell div:not(div:first-child) {
  /* Content */
  padding-left: 16px;
}

.rating-table.small th:last-child, .rating-table.small .rating-cell {
  /* Content */
  justify-content: flex-end;
}

.rating-table th:last-child, .rating-table .rating-cell {
  /* Content */
  justify-content: space-between;
}

.rating-user-title {
  /* Content */
  display: flex;
  align-items: center;
  height: 48px;
  width: 384px;
}

.rating-user-title span:not(.user-label) {
  /* Content */
  width: 36px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Position */
  margin-left: 2px;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-size: 15px;
  line-height: 19px;
  text-align: right;
  color: var(--subtask-index);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-user-title span.shift {
  /* Content */
  width: unset;
  overflow: visible;
  text-align: left;
}

.rating-user-title .user-avatar {
  /* Content */
  margin: 0 16px;
}

.rating-user-title p {
  /* Content */
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.rating-cell {
  /* Content */
  display: flex;

  /* Text */
  text-align: center;
}

.rating-cell > div {
  /* Content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rating-cell p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
}

.rating-cell span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 10px;
  line-height: 13px;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-table tr.secondary {
  /* Visual */
  background: var(--rating-secondary);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Position */
  position: relative;
}

.rating-table tr.secondary::after, .rating-table tr.secondary::before, .rating-table tr.primary::after, .rating-table tr.primary::before {
  /* Visual */
  background: var(--rating-secondary);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  content: '';
  width: 16.2px;
  height: 50px;

  /* Position */
  position: absolute;
}

.rating-table tr.secondary::before, .rating-table tr.primary::before {
  /* Position */
  left: -12px;
}

.rating-table tr.primary {
  /* Visual */
  background: var(--rating-primary);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Position */
  position: relative;
}

.rating-table tr.primary::after, .rating-table tr.primary::before {
  /* Visual */
  background: var(--rating-primary);
}

.rating-table tr.secondary:last-child td, .rating-table tr.primary:last-child td {
  /* Content */
  height: 48.2px;
}

.rating-table tr.secondary:last-child::before, .rating-table tr.primary:last-child::before {
  /* Visual */
  border-radius: 0 0 0 10px;

  /* Content */
  height: 50.2px;
}

.rating-table tr.secondary:last-child::after, .rating-table tr.primary:last-child::after {
  /* Visual */
  border-radius: 0 0 10px 0;

  /* Content */
  height: 50.2px;
}

.rating-frozen-header {
  /* Visual */
  background: var(--rating-frozen-background);
  border-radius: 10px 10px 0px 0px;
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  display: flex;
  justify-content: space-between;
  padding: 12px;

  /* Position */
  position: relative;
}

.rating-frozen-header > .rating-frozen-left {
  /* Content */
  display: flex;
  align-items: center;
}

.rating-frozen-header::after {
  /* Visual */
  background: var(--rating-frozen-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  content: '';
  width: 100%;
  height: 20px;

  /* Position */
  position: absolute;
  bottom: -19px;
  left: 0;
  z-index: -1;
}

.rating-frozen-header > .rating-frozen-left > span {
  /* Content */
  padding-left: 16px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--rating-frozen-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-frozen-header > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--rating-frozen-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-cell .green {
  /* Visual */
  color: var(--submission-green);
}

.rating-cell .red {
  /* Visual */
  color: var(--submission-red);
}

.rating-error-container {
  /* Visual */
  background: var(--subtask-background);
  border: 1px solid var(--task-table-header);
  border-radius: 10px;
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 1084px;
  height: 373px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Position */
  margin-top: 12px;
}

.rating-error-container > h2 {
  /* Content */
  width: 342px;

  /* Position */
  margin-bottom: 29px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-error-container > p {
  /* Position */
  margin-bottom: 8px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  text-align: center;
}

.rating-error-container a {
  /* Text */
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-error-container a:hover {
  /* Text */
  color: #085FDB;
}

.rating-pages-container {
  /* Content */
  display: grid;
  grid-gap: 8px;
  width: 1084px;
  grid-template-columns: repeat(27, 32px);

  /* Position */
  margin-top: 16px;
}

.rating-page-link {
  /* Visual */
  background: var(--rating-page); /* TODO: change to var */
  border-radius: 7px;
  outline: none;
  border: none;
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  width: 32px;
  height: 32px;
  padding: 0;

  /* Position */
  position: relative;

  /* Text */
  text-align: center;
}

.rating-page-link:not(.selected):hover {
  /* Visual */
  background: var(--rating-page-hover);
}

.rating-page-link.selected {
  /* Visual */
  background: #3680C4;
}

.rating-page-link > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.rating-page-link.selected > span {
  /* Text */
  color: #FFFFFF;
}

.rating-page-link.type-1 > span {
  /* Text */
  font-size: 14px;
  line-height: 18px;
}

.rating-page-link.type-2 > span {
  /* Text */
  font-size: 12px;
  line-height: 15px;
}

.rating-page-link.type-3 > span {
  /* Text */
  font-size: 10px;
  line-height: 13px;
}

.rating-page-link.type-4 > span {
  /* Text */
  font-size: 8px;
  line-height: 10px;
}

.rating-page-link.type-5 > span {
  /* Text */
  font-size: 7px;
  line-height: 9px;
}

.rating-page-avatar {
  /* Position */
  position: absolute !important;
  bottom: -6px;
  left: calc(50% - 6px);
}

.admin-container {
  /* Content */
  padding: 35px 38px 62px 34px;
  width: 1154px;
  box-sizing: border-box;
}

.admin-container > h2 {
  /* Position */
  margin-bottom: 48px;

  /* Text */
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.admin-flex {
  /* Content */
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
}

.admin-info {
  /* Content */
  width: 617px;
}

.admin-info-input {
  /* Content */
  width: auto;

  /* Position */
  margin-bottom: 24px;
}

#contest-title-input {
  /* Content */
  width: 543px;
}

#contest-description-input {
  /* Content */
  width: 543px;
  height: 107px;
}

.admin-datetime-container {
  /* Content */
  display: flex;
}

#contest-begin, #contest-registration-begin {
  /* Content */
  width: 200px;

  /* Position */
  margin-right: 16px;
}

#contest-end, #contest-registration-end {
  /* Content */
  width: 200px;
}

.admin-datetime-end {
  /* Content */
  width: 327px;
}

.admin-info-form > .contest-save-changes {
  /* Content */
  width: 214px;
  height: 40px;

  /* Position */
  margin-top: 24px;
}

.admin-link, .contest-edit__admin-list {
  /* Content */
  width: 543px;

  /* Position */
  margin-top: 48px;
}

.admin-link > p {
  /* Position */
  margin-bottom: 7px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.admin-link-flex {
  /* Content */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-link-flex > a {
  /* Content*/
  max-width: 370px;
  max-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  
  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.admin-link-flex > button {
  /* Content*/
  width: 141px;
  height: 40px;
}

.admin-link-flex > button svg {
  /* Position */
  margin-right: 9px;
}

.task-list-add-flex {
  /* Content */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-list-add-flex > .tasks-add-button {
  /* Content */
  min-width: 0;
  width: 40px;
  height: 40px;
  padding: 0;
}

.task-list-add-flex > .tasks-add-button {
  /* Position */
  margin-left: 8px;
}

#task-list-add-input {
  /* Content */
  width: 152px;
}

.task-list-add-error {
  /* Position */
  margin-top: 8px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--input-invalid-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.admin-tasks-container {
  /* Visual */
  border-left: 1px solid var(--code-line);
  transition: border var(--transition-timing) var(--transition-function);

  /* Content */
  width: 537px;
  box-sizing: border-box;
  padding-left: 33px;
}

.admin-tasks {
  /* Content */
  display: flex;

  /* Position */
  margin-bottom: 32px;
}

.task-list-droppable > div {
  /* Position */
  margin-bottom: 16px;
}

.tasks-numeration-sidebar {
  /* Content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  /* Position */
  margin-right: 20px;
}

.tasks-numeration-sidebar > span {
  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 53px;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
  vertical-align: auto;
}

.task-draggable-card {
  /* Visual */
  background: var(--body-background);
  border-radius: 10px;
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  display: flex;
  align-items: center;
  width: 435px;
  height: 53px;
  padding: 17px 24px 17px 12px;
  box-sizing: border-box;
}

.task-draggable-card > svg {
  /* Position */
  margin-right: 20px;
}

.task-draggable-card > span {
  /* Content */
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-draggable-card > a {
  /* Content */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Position */
  margin-left: auto;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: all var(--transition-timing) var(--transition-function);
}

.task-draggable-card > a > svg {
  /* Position */
  margin-left: 8px;
}

.task-draggable-card > button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 5px;

  /* Position */
  margin-left: 15px;
}

.task-list-add-flex > .tasks-save-button {
  /* Content */
  width: 221px;
  height: 40px;
  margin-left: auto;
}

.task-edit-link button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 5px;

  /* Position */
  margin-top: 27px;
  position: relative;
  left: -5px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit-link:hover button {
  /* Visual */
  color: #085FDB;
}

.task-title--flex {
  /* Content */
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.task-title__switch {
  /* Content */
  display: flex;
}

.task-category-button {
  /* Visual */
  border-radius: 95px;
  border: none;
  background: none;
  cursor: pointer;

  /* Content */
  padding: 6.5px 14px;
  
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: all var(--transition-timing) var(--transition-function);
}

.task-category-button:hover, .task-category-button.selected {
  /* Visual */
  background: var(--nav-hover-color);
}

.task-category-button:last-child {
  /* Position */
  margin-left: 8px;
}

.task-list__private-table th {
  /* Content */
  padding: 0 0 11.5px 0;
  
  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-align: left;
  color: var(--placeholder-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-list__create-button {
  /* Content */
  width: 100%;
  height: 50px;
}

.task-list-visible.empty-list {
  /* Content */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  /* Position */
  margin-top: 82px;
}

.task-list-visible.empty-list > h1 {
  /* Position */
  margin: 30px 0 0 0;

  /* Text */
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-list-visible.empty-list > p {
  /* Content */
  width: 600px;

  /* Position */
  margin: 20px 0 0 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.task-list-visible.empty-list > p a {
  /* Text */
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
  text-decoration: underline;
}

.task-list-visible.empty-list > p a:hover {
  /* Text */
  color: #085FDB;
}

.task-list-visible.empty-list > .task-list__create-button--empty {
  /* Content */
  width: 214px;
  height: 40px;

  /* Position */
  margin-top: 30px;
}

.user-label {
  /* Position */
  margin-left: 8px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--task-table-header);
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-rating__label-container {
  /* Content */
  /* display: flex;
  align-items: center;
  justify-content: flex-end; */
  text-align: right;
}

.contest-rating__header {
  /* Content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1084px;
}

.rc-overflow {
  /* Content */
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  position: relative;
  justify-content: flex-end;
}

.label-button {
  /* Visual */
  border-radius: 95px;
  border: none;
  background: none;
  cursor: pointer;

  /* Content */
  padding: 4px 14px;
  
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: all var(--transition-timing) var(--transition-function);
}

.label-button:hover, .label-button.selected {
  /* Visual */
  background: var(--nav-hover-color);
}

.label-button:not(.label-button:first-child) {
  /* Position */
  margin-left: 8px;
}

#label-selector {
  /* Content */
  width: 202px;
}
