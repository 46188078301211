.MuiIconButton-colorPrimary {
  color: var(--button-color) !important;
}

/* Button */
button.submit-button {
  /* Visual */
  background: var(--button-color);
  transition: background var(--transition-timing) var(--transition-function);

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  text-transform: none;
}

button.submit-button:hover {
  /* Visual */
  background-color: #085FDB;
}

button.submit-button.submit-disabled {
  /* Visual */
  background-color: var(--button-disabled);

  /* Text */
  color: #FFFFFF;
}

button.secondary-button {
  /* Visual */
  background: var(--additional-button-color-regular);
  border-radius: 4px;
  transition-property: color, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  padding: 12px 16px 14px 16px;

  /* Text */
  text-transform: none;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--additional-button-text);
}

button.secondary-button:hover {
  /* Visual */
  background: #2F80F226;
}

/* Loader */
.loader-container {
  /* Text */
  text-align: center;
}

.loader-container path {
  /* Visual */
  filter: invert(var(--fade-invert));
  transition: filter var(--transition-timing) var(--transition-function);
}

.loader-container > p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 30px;
  line-height: 38px;
}

/* LoaderSmall */
.loader-container.loader-small {
  /* Content */
  display: flex;
  align-items: center;

  /* Position */
  position: absolute;
}

/* SelectList */
.select-list  {
  /* Visual */
  background: #F8F8F8;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
}

/* CheckBox */
.checkbox {
  /* Visual */
  border-radius: 5px;
  background: var(--button-color);
  transition: background var(--transition-timing) var(--transition-function);
}

.MuiCheckbox-root .MuiTouchRipple-root, .MuiRadio-root .MuiTouchRipple-root {
  /* Visual */
  transform: scale(calc(1 / var(--zoom-level)));
  transform-origin: 0 0;

  /* Content */
  width: calc(100% * var(--zoom-level));
  height: calc(100% * var(--zoom-level));
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--button-color) !important;
}

.MuiCheckbox-root {
  color: rgba(calc(var(--fade-invert) * 255), calc(var(--fade-invert) * 255), calc(var(--fade-invert) * 255), 0.54) !important;
}

/* Search */
.search-field {
  /* Visual */
  border-radius: 30px;
  border: 1px solid var(--code-editor-outline);
  background: var(--body-background);
  transition-property: border, background, color;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  padding: 8px 20px;
  height: 38px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: var(--text);
}

.search-field:focus {
  /* Visual */
  outline: none;
  box-shadow: 0px 0px 0 1px #C4C4C4;
  border: 1px solid #C4C4C4;
}

.search-field::placeholder {
  /* Text */
  color: var(--placeholder-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.search-field::-ms-input-placeholder {
  /* Text */
  color: var(--placeholder-text);
  transition: color var(--transition-timing) var(--transition-function);
}

/* Radio */
.MuiFormControlLabel-root span.MuiTypography-root {
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  left: 6px;
}

/* Switch */
.Mui-checked .MuiSwitch-thumb {
  /* Visual */
  border: none;
  background: #FFFFFF;
}

/* Chatbox */
.chatbox-input {
  /* Visual */
  border: 1px solid var(--code-editor-outline);
  box-sizing: border-box;
  border-radius: 5px;
  background: var(--body-background);
  transition-property: border, background, color;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 600px;
  height: 103px;
  padding: 10px 14px;
  resize: none;
  overflow-y: hidden;

  /* Text */
  text-decoration: none;
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--text);
}

.chatbox-input:focus, .chatbox-input.emoji-press {
  /* Visual */
  outline: none;
  box-shadow: 0px 0px 0 1.3px #C4C4C4;
}

.chatbox-input::placeholder {
  /* Text */
  color: var(--placeholder-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.chatbox-input.error {
  /* Visual */
  background: var(--input-invalid-background);
  border-color: var(--input-invalid-text);
}

.chatbox-input.error:focus {
  /* Visual */
  box-shadow: 0px 0px 0 1.3px var(--input-invalid-text);
}

.chatbox-error-label {
  /* Content */
  width: 109px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--input-invalid-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.chatbox-button-container {
  /* Content */
  width: 194px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbox .chatbox-emoji-button:hover {
  /* Visual */
  background: var(--alert-separator);
}

.chatbox .chatbox-emoji-button {
  /* Visual */
  border-radius: 5px;

  /* Content */
  width: 24px;
  height: 24px;
  padding: 0;
  min-width: unset;
}

.chatbox-emoji-button img {
  /* Content */
  width: 20px !important;
  height: 20px !important;
}

.chatbox-footer {
  /* Content */
  width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  /* Position */
  margin-top: 14px;
}

.chatbox-footer > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: right;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

/* Avatar */
.user-avatar {
  /* Visual */
  border-radius: 50%;
}

/* Login */
.login-container {
  /* Visual */
  background: var(--background);
  border: 1px solid var(--container-outline);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
  border-radius: 10px;

  /* Content */
  padding: 24px 20px;
  width: 540px;

  /* Position */
  position: relative;
}

.login-container > h2 {
  /* Position */
  margin-bottom: 68px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

button.telegram-auth-button {
  /* Visual */
  background: #69A8E5;
  border-radius: 4px;

  /* Content */
  padding: 10px 26px 10px 28px;
  width: 259px;
  height: 44px;

  /* Position */
  position: absolute;
  bottom: 24px;
  left: 20px;

  /* Text */
  font-weight: 500;
}

button.telegram-auth-button svg, button.google-auth-button svg {
  /* Position */
  margin-right: 16px;
}

button.telegram-auth-button:hover {
  /* Visual */
  background: #93C4F2;
}

button.telegram-auth-button.submit-disabled {
  /* Visual */
  background: #b4d3f2;
}

button.google-auth-button {
  /* Visual */
  background: var(--background);
  border-radius: 4px;
  border: 1px solid var(--google-login-border);
  box-sizing: border-box;

  /* Content */
  padding: 11px 37px 11px 39px;
  min-width: 260px;
  height: 44px;

  /* Position */
  position: absolute;
  bottom: 24px;
  right: 20px;

  /* Text */
  font-weight: 500;
  color: var(--text);
  transition-property: color, background, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
}

button.google-auth-button:hover {
  /* Visual */
  background: var(--subtask-background);
}

button.google-auth-button > .MuiTouchRipple-root {
  /* Visual */
  border-radius: 3px;

  /* Position */
  top: -0.5px;
}

button.google-auth-button.submit-disabled {
  /* Visual */
  background: var(--background);

  /* Text */
  color: var(--google-login-disabled-text);
}

.login-container.disabled > button {
  /* Content */
  pointer-events: none;
}

/* Input */
.input-container {
  /* Content */
  display: flex;
  flex-direction: column;
}

.input-container > span {
  /* Position */
  margin-bottom: 8px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--task-table-header);
  transition: color var(--transition-timing) var(--transition-function);
}

.input-container.focus > span {
  /* Text */
  color: #4E93F5;
}

.input-main {
  /* Content */
  display: flex;
  height: 44px;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.input-main.textarea {
  /* Content */
  width: auto;
  height: 117px;
}

.input-main > input, .input-main > textarea, .input-unlabeled {
  /* Visual */
  background: var(--subtask-background);
  border: 1px solid var(--code-editor-outline);
  border-radius: 5px;
  outline: none;
  transition-property: border, background, color;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  height: 100%;
  box-sizing: border-box;
  padding: 10px 14px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--text);
}

.input-main > input {
  /* Content */
  width: 272px;
}

.input-main > textarea {
  /* Content */
  width: 473px;
  resize: none;
  overflow-y: hidden;
}

.input-main > input::placeholder, .input-main > textarea::placeholder, .input-unlabeled::placeholder {
  transition: color var(--transition-timing) var(--transition-function);
}

.input-container.focus > .input-main > input, .input-container.focus > .input-main > textarea, .input-unlabeled.focus {
  /* Visual */
  background: #1771F10D;
  border: 1px solid #1771F1BF;
}

.input-container.focus > .input-main > input::placeholder, .input-container.focus > .input-main > textarea::placeholder {
  /* Text */
  color: #1771F1BF;
}

.input-error-label {
  /* Visual */
  display: none;

  /* Content */
  width: 109px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--input-invalid-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.input-container.error .input-error-label {
  /* Visual */
  display: block;
}

.input-container.error > .input-main > input, .input-container.error > .input-main > textarea, .input-unlabeled.error {
  /* Visual */
  background: var(--input-invalid-background);
  border: 1px solid var(--input-invalid-border);

  /* Text */
  color: var(--input-invalid-text);
}

.input-container.error > .input-main > input::placeholder, .input-container.error > .input-main > textarea::placeholder, .input-unlabeled.error::placeholder {
  /* Text */
  color: #FB2A2A70;
}

.input-container.error > span {
  /* Text */
  color: var(--input-invalid-text);
}

/* Ranks */
.rank-badge, .rank-badge * {
  /* Visual */
  transition: all var(--transition-timing) var(--transition-function);
}

/* Markdown Input */
.markdown-input__placeholder, .markdown-input__input::placeholder {
  /* Text */
  color: var(--tasklist-id);
  transition: color var(--transition-timing) var(--transition-function);
}

.markdown-input__markdown > p:last-child {
  /* Position */
  margin-bottom: 0;
}

.markdown-input__placeholder, .markdown-input__markdown, .markdown-input__input {
  /* Visual */
  cursor: text;

  /* Content */
  width: 100%;
}

.markdown-input__input {
  /* Visual */
  background: none;
  outline: none;
  border: none;

  /* Content */
  overflow: hidden;
  padding: 0;
}

.markdown-input__markdown h1, .markdown-input__markdown h2, .markdown-input__markdown h3, .markdown-input__markdown h4, .markdown-input__markdown h5, .markdown-input__markdown h6 {
  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

/* AdminList */
.admin-list > p {
  /* Position */
  margin-bottom: 19px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.admin-list-item {
  /* Content*/
  display: flex;
  width: 269px;
  align-items: center;
  justify-content: space-between;
}

.admin-list-item:not(*:first-child)  {
  /* Position */
  margin-top: 16px;
}

.admin-list-item div {
  /* Content*/
  display: flex;
  width: 203px;
  align-items: center;
}

.admin-list-item > span {
  /* Text */
  font-family: "PT-Root-UI-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--google-login-disabled-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.admin-list-item > button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 5px;

  /* Position */
  position: relative;
  left: 5px;
}

.admin-list-item div > span {
  /* Content*/
  max-width: 161px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;

  /* Position */
  margin-left: 12px;

  /* Text */
  font-family: "PT-Root-UI-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.admin-list-add-container {
  /* Position */
  margin-top: 16px;
}

.admin-list-add-flex {
  /* Content */
  width: 269px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-list-add-flex > button {
  /* Content */
  min-width: 0;
  width: 40px;
  height: 40px;
  padding: 0;
}

#admin-list-add-input {
  /* Content */
  width: 221px;
}

.admin-list-add-error {
  /* Position */
  margin-top: 8px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--input-invalid-text);
  transition: color var(--transition-timing) var(--transition-function);
}
