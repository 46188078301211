.scalable {
  /* Content */
  transform: scale(var(--zoom-level));
  transform-origin: top left;
}

@supports (-moz-appearance:none) {
  .scalable.task-container {
    height: 0;
  }

  .scalable .task-content > *:last-child {
    margin-bottom: 62px;
  }

  .scalable.main-content {
    height: 0;
  }

  .scalable.main-content > *:last-child {
    margin-bottom: 62px;
  }

  .scalable #top-news {
    margin-bottom: 62px;
  }

  .scalable .task-submitted > table {
    margin-bottom: 62px;
  }

  #main-editor .ace_scroller {
    border-top: 1px solid var(--code-editor-outline);
    transition-property: background, color, border;
    transition-duration: var(--transition-timing);
    transition-timing-function: var(--transition-function);
  }

  #main-editor .ace_gutter-cell.ace_gutter-active-line  {
    border-left: 1px solid var(--code-editor-outline);
    transition-property: background, color, border;
    transition-duration: var(--transition-timing);
    transition-timing-function: var(--transition-function);
  }

  .scalable .task-list-visible {
    height: 0;
  }

  .scalable .task-list-visible > *:last-child {
    margin-bottom: 62px;
  }

  .scalable .contest-event-list:last-child {
    margin-bottom: 62px;
  }

  .contest-running {
    margin-bottom: 62px;
  }

  #contest-login:last-child {
    margin-bottom: 62px;
  }

  .announcement-container:last-child {
    margin-bottom: 62px;
  }

  .scalable .article-category:last-child {
    padding-bottom: 62px;
  }

  .scalable.article-content {
    height: 0;
  }

  .scalable.article-content > *:last-child {
    margin-bottom: 62px;
  }

  .scalable.task-container > .contest-rating > *:last-child {
    margin-bottom: 62px;
  }
}

.theme-background {
  /* Visual */
  background: var(--body-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  height: 100%;
  width: 100%;

  /* Position */
  position: fixed;
}

.App {
  /* Content */
  height: 100%;
  max-width: 1440px !important;
  width: 100% !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.navbar > a > button {
  /* Visual */
  border-radius: 95px;

  /* Content */
  padding: 6px 14px;
}

.navbar > a > button:hover {
  /* Visual */
  background: var(--nav-hover-color);
  transition: background var(--transition-timing) var(--transition-function);
}

.active > button {
  /* Visual */
  background: var(--nav-hover-color);
  transition: background var(--transition-timing) var(--transition-function);
}

.navbar {
  /* Visual */
  background: var(--body-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  padding: 14px 0px 7px 0px !important;
  height: 60px;

  /* Position */
  max-width: 1392px !important;
  width: 1392px !important;
  z-index: 10000;
  position: fixed;
}

#nav-title {
  /* Position */
  position: relative;
  margin-left: -14px;
}

#nav-title:hover {
  background: none;
}

.nav-link {
  /* Position */
  margin-left: 30px;
}

.nav-label {
  /* Text */
  text-transform: none;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

#theme-switch, #nav-avatar, #profile-name {
  /* Position */
  margin-top: -2px;
}

#profile-name {
  /* Content */
  padding: 8px;

  /* Position */
  float: right;
  margin-top: -4px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.015em;
  text-align: justify;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

#nav-avatar {
  /* Position */
  float: right;
  margin-right: 10px;
}

.content-background {
  /* Visual */
  background: var(--background);
  transition: background var(--transition-timing) var(--transition-function);
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;

  /* Content */
  width: 1392px;
  height: calc(100% / var(--zoom-level));

  /* Position */
  position: fixed;
  top: calc(62px * var(--zoom-level));
}

.content-outline {
  /* Visual */
  box-shadow: 0 0 0 2.5px var(--nav-hover-color);
  transition: box-shadow var(--transition-timing) var(--transition-function);
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;

  /* Content */
  width: 1392px;
  height: calc(100% / var(--zoom-level));
  pointer-events: none;

  /* Position */
  position: fixed;
  top: calc(62px * var(--zoom-level));
  z-index: 1000;
}

.main-content {
  /* Content */
  padding: 57px 52px;
  width: 1288px;

  /* Position */
  position: relative;
  top: calc(62px * var(--zoom-level));
}

.top-text {
  /* Content*/
  display: inline-block;
  width: 100%;

  /* Position */
  position: relative;
}

.top-text > h1 {
  /* Content */
  max-width: 848px;
}

.top-text > h2 {
  /* Content */
  max-width: 848px;
  
  /* Text */
  font-size: 32px;
  line-height: 39px;
}

.top-text > p {
  /* Content */
  width: 600px;

  /* Text */
  font-size: 20px;
  line-height: 25px;
}

.MuiOutlinedInput-root > fieldset {
  display: none;
}

@keyframes rotation-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotation-out {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

#theme-switch {
  /* Visual */
  color: #222222;

  /* Content */
  width: 36px;
  min-width: 36px;
  height: 36px;
  padding: 8px;

  /* Position */
  float: right;
  top: 1px;
  left: 6px;
}

#theme-switch:hover {
  /* Visual */
  background: none;
}

.theme-switch-rotated-in {
  /* Visual */
  animation: rotation-in 0.35s;
  transform: rotate(180deg);
}

.theme-switch-rotated-out {
  /* Visual */
  animation: rotation-out 0.35s;
}

.MuiPopover-root .MuiPaper-root {
  /* Visual */
  background: var(--background);
  transition-property: background, color;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  transform: scale(var(--zoom-level)) !important;

  /* Text */
  color: var(--text);
}

.MuiListItem-gutters {
  /* Content */
  padding-left: 12px !important;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif !important;
}

.MuiInputBase-root .MuiSelect-icon {
  /* Visual */
  filter: invert(var(--fade-invert));
  transition: filter var(--transition-timing) var(--transition-function);
}

.MuiSelect-icon {
  /* Visual */
  color: #BDBDBD !important;
}

.tasklist-loader {
  /* Position */
  position: relative;
  top: -48px;
  z-index: -1;
}

.tasklist-end {
  /* Position */
  margin: 18px 0 0 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  text-align: center;
  font-size: 18px;
}

.mobile-alert {
  /* Visual */
  background: var(--background);

  /* Content */
  width: 100%;
  min-height: 100vh;

  /* Position */
  position: absolute;
  z-index: 10001;
  left: 0;
}

.mobile-alert-container {
  /* Content */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-alert-container > svg {
  /* Position */
  margin-top: 30px;
}

.mobile-alert-container > img {
  /* Position */
  margin-top: 70px;
}

.mobile-alert-container > h2 {
  /* Content */
  max-width: 331px;
  width: 85vw;

  /* Position */
  margin-top: 45px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.015em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.mobile-alert-container > p {
  /* Content */
  max-width: 331px;
  width: 85vw;

  /* Position */
  margin-top: 20px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.mobile-alert-container > .mobile-ok {
  /* Content */
  max-width: 300px;
  height: 40px;
  width: 85vw;

  /* Position */
  margin-top: 40px;
  margin-bottom: 32px;
}

div.MuiPopover-root[role="presentation"] {
  /* Position */
  z-index: 100000 !important;
}

/* Visual */
/* Content */
/* Position */
/* Text */
