.task-edit-container {
  /* Position */
  position: relative;
  top: calc(62px * var(--zoom-level));
}

.task-edit-sidebar {
  

  /* Content */
  padding: 0 22px 0 20px;
  box-sizing: border-box;
  width: 238px;

  /* Position */
  position: fixed;
  top: calc(62px * var(--zoom-level));
  bottom: 0;
}

.task-edit-sidebar > .task-sidebar__scroll-wrapper::before {
  /* Visual */
  border-right: 2px solid var(--nav-hover-color);
  transition: border var(--transition-timing) var(--transition-function);
  
  /* Content */
  content: '';
  pointer-events: none;

  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% / var(--zoom-level));
  width: 100%;
}

.task-edit-sidebar > .task-sidebar__scroll-wrapper {
  /* Content */
  padding-top: 20px;
  overflow-y: hidden;
  height: calc(100% / var(--zoom-level));
}

.task-sidebar__scroll-wrapper > *:last-child {
  /* Position */
  padding-bottom: 62px;
}

.task-sidebar__scroll-wrapper > a {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-sidebar__scroll-wrapper a:hover {
  /* Text */
  color: var(--task-link-hover);
}

.task-sidebar__scroll-wrapper > p {
  /* Content */
  word-break: break-word;

  /* Position */
  margin: 33px 0 0 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.task-sidebar__link-list {
  /* Content */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* Position */
  margin-top: 16px;
}

.task-sidebar__link {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  width: 100%;
  padding: 2px 0;
  
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: left;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-sidebar__link:not(*:first-child) {
  /* Position */
  margin-top: 6px;
}

.task-sidebar__link:hover {
  /* Visual */
  color: var(--task-link-hover);
}

.task-sidebar__link:disabled {
  /* Visual */
  color: #3680c4;
  cursor: default;
}

.task-edit-content {
  /* Content */
  padding: 35px 36px 62px 36px;
  box-sizing: border-box;
  width: 1154px;

  /* Position */
  top: calc(62px * var(--zoom-level));
  position: relative;
  margin-left: calc(238px * var(--zoom-level));
}

.has-error {
  /* Content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.task-edit-content.has-error > h2 {
  /* Position */
  margin: 0 0 20px 0;

  /* Text */
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
}

.task-edit-content.has-error > p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.task-edit__block {
  /* Content */
  width: 600px;

  /* Position */
  margin-top: 32px;
}

.task-edit__block > div {
  /* Content */
  display: flex;
  min-height: 20px;
}

.task-edit__title, .task-edit__legend {
  /* Content */
  display: flex;
  width: 600px;
}

.task-edit__title > .title__markdown > p, .task-edit__title > .title__input {
  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 37px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__title textarea, .task-edit__legend textarea, .task-edit__block textarea {
  /* Text */
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__title > .title__markdown > p {
  /* Content */
  word-break: break-word;
}

.task-edit__title > .title__placeholder {
  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 37px;
  letter-spacing: 0;
  text-align: left;
}

.task-edit__legend {
  /* Position */
  margin-top: 32px;
}

.task-edit__legend > .legend__placeholder,
.task-edit__legend > .legend__input,
.task-edit__legend p,
.task-edit__block li,
.task-edit__block textarea {
  /* Text */
  font-family: PT-Root-UI;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
}

.task-edit__block .markdown-input__markdown h1,
.task-edit__block .markdown-input__markdown h2,
.task-edit__block .markdown-input__markdown h3,
.task-edit__block .markdown-input__markdown h4,
.task-edit__block .markdown-input__markdown h5,
.task-edit__block .markdown-input__markdown h6 {
  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
}

.markdown-input__placeholder {
  /* Text */
  font-family: PT-Root-UI;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
}

.task-edit__block h3 {
  /* Position */
  margin-bottom: 12px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__block p, .task-edit__legend p {
  /* Content */
  word-wrap: break-word;
}

.task-edit__block code, .task-edit__legend code {
  /* Visual */
  background: var(--sample-background);
  transition: background var(--transition-timing) var(--transition-function);
  border-radius: 4px;

  padding: 1px;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
}

.task-edit__block li:not(:last-child), .task-edit__legend li:not(:last-child) {
  /* Position */
  margin-bottom: 5px;
}

.task-edit__block .markdown-input__markdown > *:last-child {
  /* Position */
  margin-bottom: 0;
}

.task-edit__block li, .task-edit__legend li {
  /* Text */
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);

  /* Text */
  font-family: PT-Root-UI;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit-content > .task-edit__save-button {
  /* Content */
  width: 214px;
  height: 40px;

  /* Position */
  margin-top: 64px;
}

.task-edit__samples-table {
  /* Content */
  border-collapse: separate;
  border-spacing: 0 12px;

  /* Position */
  margin-top: 32px;
}

.task-edit__samples-table th {
  /* Content */
  padding: 0;
  width: 292px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  color: var(--task-table-header);
  transition: color var(--transition-timing) var(--transition-function);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: .01em;
  text-transform: uppercase;
  text-align: left;
}

.task-edit__samples-table td {
  /* Content */
  padding: 0;
}

.task-edit__samples-table td:not(td:last-child) {
  /* Visual */
  background: var(--sample-background);
  transition-property: background, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 292px;
  vertical-align: top;
}

.task-edit__samples-table td:not(*:first-child) {
  /* Visual */
  border-left: 10px solid var(--background);
  transition: border var(--transition-timing) var(--transition-function);
}

.task-edit__samples-table .task-edit__sample-input {
  /* Visual */
  background: var(--sample-background);
  transition-property: background, color;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
  border: none;
  outline: none;

  /* Content */
  padding: 8px 8px 8px 10px;
  width: 274px;

  /* Text */
  font-family: JetBrains-Mono, Tahoma, Sans-Serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text);
}

.task-edit__samples-table .task-edit__sample-input::placeholder {
  /* Text */
  color: var(--tasklist-id);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__samples-table button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 5px;
  height: 26px;
  width: 26px;
  box-sizing: border-box;

  /* Position */
  position: relative;
  left: -5px;
}

.task-edit__samples-table button * {
  /* Visual */
  transition: fill var(--transition-timing) var(--transition-function);
}

.task-edit__samples-table button:hover * {
  /* Visual */
  fill: var(--difficulty);
}

.task-edit__add-sample {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;

  /* Position */
  position: relative;
  left: -5px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__add-sample path {
  /* Visual */
  transition: fill var(--transition-timing) var(--transition-function);
}

.task-edit__add-sample:hover, .task-edit__add-sample:hover path {
  /* Visual */
  color: #085FDB;
  fill: #085FDB;
}

.task-edit-content.full-height {
  /* Content */
  height: calc(100% - 62px);
}

.task-edit-content .markdown-input__markdown img {
  /* Content */
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.task-edit__drop-container {
  /* Content */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.task-edit__drop-container > span {
  /* Content */
  width: 300px;
  height: 44px;

  /* Position */
  margin-top: 16px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #888888;
}

.task-edit__loaded-message {
  /* Content */
  display: flex;
  align-items: center;
}

.task-edit__loaded-message > p {
  /* Position */
  margin: 0 0 0 22px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
}

.task-edit__loaded-buttons {
  /* Content */
  display: flex;
  align-items: center;
}

.task-edit__loaded-buttons > button {
  /* Visual */
  background: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 6px 8px;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__loaded-buttons > .download {
  /* Visual */
  color: var(--button-color);

  /* Position */
  margin-left: 33px;
}

.task-edit__loaded-buttons > .download:hover {
  /* Visual */
  color: #085FDB;
}

.task-edit__loaded-buttons > .delete {
  /* Visual */
  color: var(--additional-button-text-red);

  /* Position */
  margin-left: 11px;
}

.task-edit__loaded-buttons > .delete:hover {
  /* Visual */
  color: #E02D2D;
}

.task-edit__admin-list {
  /* Position */
  margin-top: 13px;
}

.task-edit__limits {
  /* Position */
  margin-top: 42px;
}

.task-edit__limits > h3 {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.task-edit__limits-input-container {
  /* Position */
  margin-top: 24px;
}

.task-edit__limits-input-container > div:last-child {
  /* Position */
  margin-top: 24px;
}

.task-edit__limits > .task-edit__limits-submit {
  /* Content */
  width: 256px;
  height: 40px;
    
  /* Position */
  margin-top: 24px;
}

#task-edit__time-input, #task-edit__memory-input {
  /* Content */
  width: 256px;
}

.task-edit__limits-input-container .input-main {
  width: 387px;
}
