.profile-container {
  /* Content */
  display: flex;
  justify-content: space-between;
}

.profile-main-block {
  /* Content */
  width: 600px;
}

.profile-back-link {
  /* Visual */
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-back-link:hover {
  /* Text */
  color: var(--task-link-hover);
}

.profile-error-block > h2 {
  /* Position */
  margin-bottom: 16px;
  margin-top: 40px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-style: bold;
  font-size: 40px;
  line-height: 51px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-error-block > p {
  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-size: 20px;
  line-height: 25px;
}

.profile-personal-block {
  /* Content */
  display: flex;

  /* Position */
  margin-top: 40px;
}

.profile-personal-block:not(:last-child) {
  /* Position */
  margin-bottom: 56px;
}

.profile-personal-block > img {
  /* Position */
  margin-right: 32px;
}

.profile-name > h2 {
  /* Content */
  width: 468px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Position */
  margin-bottom: 4px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-style: bold;
  font-size: 48px;
  line-height: 59px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-name > p {
  /* Content */
  width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 20px;
  line-height: 25px;
}

.profile-auth-block {
  /* Visual */
  border-bottom: 1px solid var(--nav-hover-color);
  transition: border var(--transition-timing) var(--transition-function);

  /* Content */
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}
.profile-auth-block:not(:last-child) {
  /* Position */
  margin-bottom: 24px;
}

.profile-account {
  /* Visual */
  border-radius: 8px;
  background: var(--body-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  width: 257px;
  height: 90px;
  padding: 13px 16px;
  display: flex;
  flex-direction: column;

  /* Position */
  position: relative;
}

.profile-account > div {
  /* Content */
  display: flex;
  align-items: center;
}

.profile-account > div > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-account.telegram > div > span {
  /* Position */
  margin-left: 16px;
}

.profile-account.google > div > span {
  /* Position */
  margin-left: 18px;
}

.profile-account > span {
  /* Content */
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Position */
  margin-top: 14px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-account > button {
  /* Visual */
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  /* Content */
  padding: 0;

  /* Position */
  position: absolute;
  bottom: 13px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-account > button.profile-disconnect {
  color: var(--submission-red);
}

.profile-bio-block:not(:last-child) {
  /* Position */
  margin-bottom: 24px;
}

.profile-bio-block > h2 {
  /* Position */
  margin-bottom: 12px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-bio-block > p {
  /* Content */
  width: 600px;

  /* Position */
  margin: 0;

  /* Text */
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-since-block:not(:last-child) {
  /* Position */
  margin-bottom: 24px;
}

.profile-since-block > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  color: #8B8B8B;
  transition: color var(--transition-timing) var(--transition-function);
}

button.secondary-button.profile-edit-button {
  /* Content */
  width: 155px;
  height: 44px;
}

button.secondary-button.profile-exit-button {
  /* Visual */
  background: var(--additional-button-color-red);
  transition-property: color, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 155px;
  height: 44px;

  /* Position */
  margin-left: 32px;

  /* Text */
  color: var(--additional-button-text-red);
}

button.secondary-button.profile-exit-button:hover {
  /* Visual */
  background: #EF393926;
}

.profile-telegram-proposal {
  /* Visual */
  background: #95C1EC;
  border-radius: 8px;

  /* Content */
  width: 601px;
  height: 237px;
  padding: 22px 27px;
  box-sizing: border-box;

  /* Position */
  position: relative;
}

.profile-telegram-proposal > svg {
  /* Position */
  position: absolute;
  right: 0;
  bottom: 0;
}

.profile-telegram-proposal > h2 {
  /* Position */
  margin-bottom: 14px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.profile-telegram-proposal > ul {
  /* Conten */
  padding-inline-start: 27px;

  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.profile-telegram-proposal > .telegram-auth-button {
  /* Position */
  position: absolute;
  left: 33px;
  bottom: 31px;
}

.profile-edit-block {
  /* Position */
  margin-top: 40px;
}

.profile-edit-block > h2 {
  /* Position */
  margin-bottom: 40px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-avatar-edit > span {
  /* Text */
  text-transform: uppercase;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: var(--task-table-header);
  transition: color var(--transition-timing) var(--transition-function);
}

.profile-avatar-edit > div {
  /* Content */
  display: flex;
  align-items: center;

  /* Position */
  margin-top: 15px;
}

.profile-upload-avatar {
  /* Content */
  width: 155px;
  height: 44px;
}

.profile-avatar-edit > div > img {
  /* Position*/
  margin-right: 32px;
}

.profile-text-edit {
  /* Content */
  display: flex;
  flex-direction: column;

  /* Position */
  margin-top: 40px;
}

.profile-text-edit > * {
  /* Position */
  margin-bottom: 40px;
}

.profile-save-edit {
  /* Content */
  width: 214px;
  height: 44px;
}

button.secondary-button.profile-avatar-upload {
  /* Content */
  padding: 0;
  width: 155px;
  min-height: 44px;
}

.profile-award-block {
  /* Content */
  width: 596px;

  /* Position */
  margin-top: 63px;
}

.profile-award-block > div {
  /* Visual */
  background: var(--body-background);
  transition: background var(--transition-timing) var(--transition-function);
}

.profile-award-block > div:not(:first-child) {
  /* Position */
  margin-top: 2px;
}

.profile-award-block > div:first-child {
  /* Visual */
  border-radius: 8px 8px 0px 0px;
}

.profile-award-block > div:last-child {
  /* Visual */
  border-radius: 0px 0px 8px 8px;
}

.profile-award-block > div.award-hidden-container {
  /* Visual */
  border-radius: 8px;

  /* Content */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.award-hidden-container > p {
  /* Position */
  margin-top: 24px;
  margin-bottom: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--text);
}

.award-rank-container {
  /* Content */
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.award-rank-container > span {
  /* Position */
  margin-top: 20px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.award-rank-container > span > a {
  /* Text */
  text-decoration: underline;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.award-rank-container > span > a:hover {
  /* Text */
  color: #085FDB;
}

.award-rank-container > div {
  /* Content */
  display: flex;
  align-items: center;
}

.award-rank-container > div > span {
  /* Position */
  margin-left: 32px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.award-stats-container {
  /* Content */
  padding: 24px 20px 23px 20px;
}

.award-stats-container > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: #ACACAC;
}

.tasks-solved > h3 {
  /* Position */
  margin-bottom: 5px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  color: var(--highlighted-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.tasks-solved > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--highlighted-text);
  transition: color var(--transition-timing) var(--transition-function);
}

.tasks-counter {
  /* Content */
  display: flex;

  /* Position */
  margin: 18px 0;
}

.tasks-counter > *:not(:first-child) {
  /* Position */
  margin-left: 12px;
}

.tasks-counter > span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.award-cups-container {
  /* Content */
  padding: 22px 21px 30px 20px;
}

.award-cups-container > p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.cups-block {
  /* Content */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  
  /* Position */
  margin-top: 16px;
}

.cups-block.silver {
  /* Content */
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.cups-block > .cups-column >*:not(:first-child) {
  /* Position */
  margin-top: 12px;
}

.platinum-trophy {
  /* Visual */
  background: var(--rank-gold-background);
  border: 1px solid var(--rank-gold-border);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 177px;
}

.gold-trophy {
  /* Visual */
  background: var(--rank-gold-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  width: 177px;
}

.silver-trophy {
  /* Visual */
  background: var(--rank-silver-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  width: 128px;
}

.trophy {
  /* Visual */
  border-radius: 10px;

  /* Content */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 6px;
}

.trophy > h3 {
  /* Position */
  margin: 7px 0;

  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.trophy > p {
  /* Position */
  margin: 0;
  
  /* Text */
  text-transform: capitalize;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.award-placement-container {
  
}
