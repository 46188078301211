#top-login {
  /* Position */
  margin-top: 43px;
}

#top-headers-table .container-small {
  /* Visual */
  background: var(--background);
  border: 1px solid var(--container-outline);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 10px;

  /* Position */
  position: relative;

  /* Content */
  padding: 54px 19px 24px 24px !important;
  flex-basis: 100%;
  width: 400px;
  box-sizing: border-box;
}

.container-small > h3 {
  /* Position */
  margin: 0px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  transition: color var(--transition-timing) var(--transition-function);
}

.container-small > p {
  /* Position */
  margin-bottom: 0px;
  margin-top: 12px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
}

.container-small > p > a {
  /* Visual */
  transition: color var(--transition-timing) var(--transition-function);
}

.container-small > img {
  /* Position */
  position: absolute;
  top: -48px;

  /* Content */
  width: 80px;
  height: 80px;
}

#top-headers-table {
  /* Position */
  margin-top: 145px;

  /* Content */
  display: flex;
  justify-content: space-between;
  column-gap: 44px;
}

#top-news {
  /* Content */
  width: 100%;

  /* Position */
  position: relative;
  margin-top: 100px;
  float: left;
}

#top-news > h2.header {
  /* Text */
  font-size: 36px;
  line-height: 44px;
}

.horizontal-scroll-container {
  /* Content */
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: space-between;
  column-gap: 50px;
}

.horizontal-scroll-container > .container-news {
  /* Visual */
  background: var(--news-background);
  border-radius: 5px;
  border: 1px solid var(--news-border);
  transition-property: border, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  flex: 0 0 auto;
  padding: 24px 20px;
  width: auto;
  max-width: 720px;
  display: flex;
}

.horizontal-scroll-container button {
  /* Visual */
  background: var(--additional-button-color-light);
  border-radius: 10px;
  transition-property: color, background;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 100%;
  height: 100%;

  /* Text */
  text-transform: none;
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: var(--button-color);
}

.horizontal-scroll-container button:hover {
  /* Visual */
  background: rgba(23, 113, 241, 0.15);
}

.horizontal-scroll-container .news-more-button {
  /* Content */
  min-width: 160px;
  min-height: 160px;
}

.news-fade-block {
  /* Visual */
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
  transition: filter var(--transition-timing) var(--transition-function);
  -webkit-transition: -webkit-filter var(--transition-timing) var(--transition-function);
  -moz-transition: -moz-filter var(--transition-timing) var(--transition-function);
  -moz-transition: filter var(--transition-timing) var(--transition-function);
  -ms-transition: -ms-filter var(--transition-timing) var(--transition-function);
  -o-transition: -o-filter var(--transition-timing) var(--transition-function);
  filter: invert(var(--fade-invert));

  /* Content */
  width: 10%;
  height: 242px;

  /* Position */
  position: absolute;
  top: 74px;
  right: -1px;
}

.container-news > img {
  /* Visual */
  border-radius: 5px;

  /* Content */
  height: 192px;
}

.news-preview-text {
  /* Position */
  margin-top: 16px;
  
  /* Text */
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}

.news-date {
  /* Visual */
  color: var(--news-date);
  transition: color var(--transition-timing) var(--transition-function);

  /* Position */
  position: absolute;
  bottom: 0px;

  /* Text */
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

#task-alert-container {
  /* Position */
  position: absolute;
  z-index: 10000;
  top: 0;
  bottom: 0;
}

.home-telegram-proposal {
  /* Content */
  display: flex;
  align-items: center;

  /* Position */
  position: relative;
  margin-top: 124px;
}

.home-telegram-proposal > h2 {
  /* Content */
  width: 506px;

  /* Position */
  margin: 0;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 40px;
  line-height: 49px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.home-telegram-block {
  /* Content */
  display: flex;
  height: 52px;
}

.home-telegram-block > img {
  /* Visual */
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.home-telegram-block > div {
  /* Position */
  margin-left: 19px;
}

.home-telegram-block h3 {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.home-telegram-block span {
  /* Content */
  display: flex;
  align-items: center;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #69A8E5;
  transition: color var(--transition-timing) var(--transition-function);
}

.home-telegram-block span > svg {
  /* Visual */
  fill: #69A8E5;
  transition: fill var(--transition-timing) var(--transition-function);
}

.home-telegram-block:hover span, .home-telegram-block:hover svg {
  /* Visual */
  fill: var(--button-color);

  /* Text */
  color: var(--button-color);
}

.home-telegram-block span > svg {
  /* Position */
  margin-right: 8px;
}
