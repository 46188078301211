#contest-search {
  /* Content */
  width: 500px; /* TODO: undo width: 700px; */
}

#contest-top-bar {
  /* Content */
  display: flex;
  justify-content: flex-start; /* TODO: undo justify-content: space-between; */
  column-gap: 24px;

  /* Position */
  margin-top: 12px;
}

.top-text > p.contest-top-description {
  /* Content */
  width: 700px;

  /* Text */
  font-size: 18px;
  line-height: 23px;
}

.contest-event-list {
  /* Content */
  display: grid;
  grid-template-columns: 408px auto auto;
  grid-gap: 32px;

  /* Position */
  margin-top: 32px;
}

.contest-container {
  /* Visual */
  background: var(--background);
  border: 1px solid var(--container-outline);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  transition-property: background, border;
  transition-duration: var(--transition-timing);
  transition-timing-function: var(--transition-function);

  /* Content */
  width: 408px;
  height: 166px;
  padding: 24px 32px 24px 22px;

  /* Position */
  position: relative;
}

.contest-container > p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
}

.contest-container-bottom {
  /* Position */
  position: absolute;
  bottom: 22px;
}

.contest-container-bottom span {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-container-bottom p {
  /* Position */
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 14px;
  line-height: 18px;
}

label.contest-hide-solved {
  /* Position */
  margin-top: -2px;
  margin-right: 6px;
  margin-left: -8px;
}

.archive-end {
  /* Content */
  width: 100%;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  text-align: center;
  font-size: 18px;
}

.upcoming-back-link {
  /* Visual */
  cursor: pointer;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--difficulty);
  transition: color var(--transition-timing) var(--transition-function);
}

.upcoming-back-link:hover {
  /* Text */
  color: var(--task-link-hover);
}


.contest-info {
  /* Content */
  width: 825px;

  /* Position */
  margin-top: 24px;
}

.contest-info > h2 {
  /* Position */
  margin-bottom: 24px;

  /* Text */
  font-family: Gilroy, Tahoma, Sans-Serif;
  font-weight: 800;
  font-size: 36px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-info > p {
  /* Content */
  width: 600px;

  /* Text */
  font-size: 16px;
  line-height: 20px;
}

.contest-info a {
  /* Visual */
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-info a:hover {
  /* Visual */
  color: #085FDB;
}

.contest-point-info {
  /* Content */
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

.contest-point-info:last-child {
  /* Content */
  padding-bottom: 0;
}

.contest-point-info > p {
  /* Position */
  margin-bottom: 0;
  margin-left: 16px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 20px;
  line-height: 25px;
}

.contest-point-info > svg {
  /* Visual */
  fill: var(--text);
  transition: fill var(--transition-timing) var(--transition-function);
}

.contest-info img {
  /* Content */
  width: 600px;
}

.contest-info > p > span {
  /* Visual */
  transition: color var(--transition-timing) var(--transition-function);
}

#contest-login {
  /* Position */
  margin-top: 48px;
}

.contest-registered-text {
  /* Content */
  width: 450px;

  /* Position */
  margin-bottom: 0;
  margin-left: 16px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 20px;
  line-height: 25px;
  color: var(--button-color);
}

#contest-register {
  /* Content */
  width: 418px;
  height: 40px;

  /* Position */
  margin-top: 48px;
}

.contest-running {
  /* Visual */
  border-radius: 10px;
  background: var(--subtask-background);
  transition: background var(--transition-timing) var(--transition-function);

  /* Content */
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Position */
  margin-top: 48px;
}

.contest-running > p {
  /* Content */
  width: 206px;
  margin: 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-size: 18px;
  line-height: 23px;
  color: var(--highlighted-text);
}

.contest-enter-button {
  /* Content */
  width: 134px;
  height: 44px;
}

.top-text.contest-flex {
  /* Content */
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.contest-switch {
  /* Content */
  display: flex;
}

.contest-category-button {
  /* Visual */
  border-radius: 95px;
  border: none;
  background: none;
  cursor: pointer;

  /* Content */
  padding: 6.5px 14px;
  
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--text);
  transition: all var(--transition-timing) var(--transition-function);
}

.contest-category-button:hover, .contest-category-button.selected {
  /* Visual */
  background: var(--nav-hover-color);
}

.contest-category-button:not(*:first-child) {
  /* Position */
  margin-left: 8px;
}

.contest-list.empty {
  /* Content */
  display: flex;
  flex-direction: column;
  align-items: center;
  
  /* Position */
  margin-top: 100px;
}

.contest-list.empty > h1 {
  /* Position */
  margin: 30px 0 0 0;

  /* Text */
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.contest-list.empty > p {
  /* Position */
  margin: 20px 0 0 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.contest-list.empty > p a {
  /* Text */
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);
  text-decoration: underline;
}

.contest-list.empty > p a:hover {
  /* Text */
  color: #085FDB;
}

.create-contest-button {
  /* Content */
  width: 214px;
  height: 40px;
}

.contest-list.empty .create-contest-button {
  /* Position */
  margin-top: 20px;
}

.contest-list > p {
  /* Position */
  margin: 0 0 32px 0;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.contest-list .create-contest-button {
  /* Position */
  margin-top: 32px;
}
