.privacy-policy-container h3 {
  /* Position */
  margin-bottom: 10px;

  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.privacy-policy-container ol {
  padding-inline-start: 24px;
}

.privacy-policy-container li {
  /* Text */
  overflow-wrap: break-word;
  font-family: PT-Root-UI, Tahoma, Sans-Serif;
  font-style: normal;
  font-weight: 400;
  color: var(--text);
  transition: color var(--transition-timing) var(--transition-function);
}

.privacy-policy-container li:not(:first-child) {
  /* Position */
  margin-top: 24px;
}

.privacy-policy-container li::marker {
  /* Text */
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}
